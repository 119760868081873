<template>
  <b-modal id="modal-user-register"
    title="เข้าสู่ระบบ"
    v-model="modalLoginShow"
    hide-footer hide-header
    class="p-0 bg_tran">

    <div class="modal-content2">
        <div class="modal-header">
          <div class="modal-close" @click="$bvModal.hide('modal-user-register')">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
          <div class="modal-header-inner">
            <!-- <span class="modal-title">ลงชื่อเข้าใช้</span> -->
            <div class="btn-group w-100" role="group" aria-label="Basic example">
              <button type="button" class="btn btn-secondary" @click="openLogin">ลงชื่อเข้าใช้</button>
              <button type="button" class="btn btn-success ">สมัครสมาชิก</button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="col-12 col-sm-12 text-center pb-2 pt-3">
            <img src="@/assets/images/5starcirclewhite.png" class="p-5" loading="lazy" alt="" width="70%" v-if="AGENT_S_BASE=='5STARLOTTO'">
            <img :src="require('@/assets/menu_top/regis5.png')" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="50%" v-else/>
            </div>
          <!-- step 1 -->
          <div class="row form-special m-0" v-if="!smsVerified">
            <div class="col-12 col-sm-12 mb-1 mt-2 text-center">
              <h2>ยืนยันเบอร์โทรศัพท์</h2>
            </div>
            <div class="col-12 col-sm-12 col-md-3 mb-1  pr-0  d-flex align-items-center">
              <span class="flag-icon flag-icon-th mr-1"></span>
              <span class="pt-1 mr-2">+66</span>
            </div>
            <div class="col-12 col-sm-12 col-md-12 mb-2 input-holder " id="phone">
                <input
                type="mobile"
                name="mobile"
                pattern="\d*"
                class="form-control x-form-control"
                maxlength="10"
                required
                placeholder="ใส่เบอร์โทรศัพท์"
                v-model="mobile"
              />
            </div>
            <div class="col-12 my-3">
              <div @click="onVerifyOtp" class="button button-big button-signup button-round mgt15">ถัดไป</div>
            </div>
          </div>
          <div class="row form-special m-0" v-if="smsVerified">
            <div class="col-12 col-sm-12 mb-1 mt-2 text-center">
              <h2>สร้างบัญชี</h2>
            </div>
            <div class="col-12 col-sm-12 col-md-3 mb-1 pr-0  d-flex align-items-center">
              <b class="reg-color ">ชื่อผู้ใช้งาน</b>
            </div>
            <div class="col-12 col-sm-12 col-md-8 mb-2 input-holder " id="username">
              <input
                type="text"
                class="form-control x-form-control"
                name="username"
                placeholder="username (0-9,a-z)"
                v-model.trim="$v.username.$model"
                required
              />
              <span id="username_text"></span>
            </div>
            <div class="col-12 col-sm-12 col-md-3 mb-1 pr-0  d-flex align-items-center">
              <b class="reg-color ">รหัสผ่าน</b>
            </div>
            <div class="col-12 col-sm-12 col-md-8 mb-2 input-holder " id="password">
              <input
                type="password"
                class="form-control x-form-control"
                name="password"
                id="password_val"
                placeholder="password"
                v-model.trim="$v.password.$model"
                required
              />
              <span id="password_text"></span>
            </div>
            <div class="col-12 col-sm-12 col-md-3 mb-1 d-flex pr-0 align-items-center">
              <b class="reg-color ">ยืนยันรหัสผ่าน</b>
            </div>
            <div class="col-12 col-sm-12 col-md-8 mb-2 input-holder " id="repassword">
              <input
                type="password"
                class="form-control x-form-control"
                name="repassword"
                id="repassword_val"
                placeholder="กรอก password อีกครั้ง"
                v-model.trim="$v.password_confirmation.$model"
                required
              />
              <span id="repassword_text"></span>
            </div>
            <div class="col-12 col-sm-12 col-md-3 mb-1 d-flex pr-0 align-items-center" v-if="this.promotion_enable">
              <b class="reg-color ">โปรโมชั่น</b>
            </div>
            <div class="col-12 col-sm-12 col-md-8 mb-2 input-holder " id="repassword" v-if="this.promotion_enable">
              <select class="form-control x-form-control" name="show_promotion" v-model="promotoion_id" >
                <option value="0">-</option>
                <option :value="item.id" v-for="(item,index) in getAgentPromotionsRegister"  v-bind:key="index">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-12 my-3">
              <div @click="onCancelRegister" class="button button-big button-cancel button-round mgt15 mx-2">ยกเลิก</div>
              <div @click="onSubmitRegister" class="button button-big button-signup button-round mgt15 mx-2">ยืนยัน</div>
            </div>
          </div>
        </div>
      </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { checkSpecialChar } from '@/utils/string'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'Home',
  data () {
    return {
      AGENT_S_BASE: process.env.VUE_APP_AGENT_BASE,
      mobile: '',
      // Step 2
      smsotp: '',
      smsVerified: false,
      showGuideOTP: false,
      username: '',
      password: '',
      password_confirmation: '',
      casinoEnable: false,
      indexNews: 0,
      modalLoginShow: false,
      headerBgVariant: 'bgModalHeaderLogin',
      headerTextVariant: 'dark',
      bodyBgVariant: 'primary-login',
      bodyTextVariant: 'dark',
      footerBgVariant: 'dark',
      footerTextVariant: 'dark',
      member_username: null,
      member_password: null,
      promotion_enable: process.env.VUE_APP_PROMOTION_ENABLE,
      promotoion_id: 0
    }
  },
  computed: {
    ...mapState({
      afKey: (state) => state.registerModule.afKey,
      agKey: (state) => state.registerModule.agKey
    }),
    ...mapGetters(['getAgentPromotionsRegister', 'getPermisstionRegister'])
  },
  mounted () {
    console.log('afKey =' + this.afKey)
    console.log('agKey =' + this.agKey)
    console.log('LOCK_REG_AG =' + process.env.VUE_APP_LOCK_REG_AG)
    const ag = (process.env.VUE_APP_LOCK_REG_AG ? process.env.VUE_APP_AG_USER : (this.agKey ?? process.env.VUE_APP_AG_USER))
    console.log('real agKey =' + process.env.VUE_APP_LOCK_REG_AG, process.env.VUE_APP_AG_USER, ag)
    // this.clearAfKey()
    // console.log('afKey =' + this.afKey)
  },
  methods: {
    ...mapActions(['reqConfig', 'registerVerifyOtp', 'clearAfKey', 'clearAgKey', 'registerCreateUser', 'setLoading', 'loginAction', 'openErrorPopup', 'openInfoPopup', 'requestUsername', 'getMyAccountBanks', 'getUserInfo', 'getPromotionsList', 'registerSendOTP']),
    openLine () {
      window.open(this.getAgentNews.link)
    },
    async login () {
      if (!this.member_username && !this.member_password) {
        return false
      } else {
        this.setLoading(true)
        const isSucces = await this.loginAction({
          username: this.member_username,
          password: this.member_password
        })
        if (isSucces) {
          this.setLoading(false)
          this.$router.replace('/hotgame')
          this.modalLoginShow = false
          this.member_username = null
          this.member_password = null
          this.requestUsername()
          this.getMyAccountBanks()
          this.getUserInfo()
          this.getPromotionsList()
          this.reqConfig(process.env.VUE_APP_AG_ID)
          // window.location.reload()
        } else {
          this.setLoading(false)
          this.$refs.loginform.reset()
          return false
        }
      }
    },
    onCancelRegister () {
      this.smsVerified = false
    },
    async onVerifyOtp () {
      this.smsVerified = true
    },
    async sendSms () {
      const isSucces = await this.registerSendOTP({
        mobile: this.mobile
      })
      if (isSucces) {
        this.showGuideOTP = true
        this.openInfoPopup('ระบบส่ง SMS ไปแล้ว เช็คข้อความบนมือถือของคุณ')
      }
    },
    async onSubmitRegister () {
      this.setLoading(true)
      this.submited = true
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) {
        window.scrollTo(0, 0)
        this.openErrorPopup('กรุณากรอกข้อมูลให้เรียบร้อย')
        this.setLoading(false)
      } else {
        const isSucces = await this.registerCreateUser({
          username: this.username,
          password: this.password,
          password_confirmation: this.password_confirmation,
          af: this.afKey ? this.afKey : '',
          ag: (process.env.VUE_APP_LOCK_REG_AG ? process.env.VUE_APP_AG_USER : (this.agKey ?? process.env.VUE_APP_AG_USER)),
          mobile: this.mobile,
          otp: this.smsotp,
          promotoion_id: this.promotoion_id
        })

        if (isSucces) {
          this.clearAfKey()
          this.clearAgKey()
          const isSucces = await this.loginAction({
            username: this.username,
            password: this.password
          })
          if (isSucces) {
            this.setLoading(false)
            this.submited = false
            this.requestUsername()
            this.reqConfig(process.env.VUE_APP_AG_ID)
            this.$bvModal.hide('modal-user-register')
            this.$bvModal.hide('modal-user-login')
            this.$bvModal.show('modal-user-register_success')
            this.$router.replace('/member/settings/bank/add')
            // this.$router.replace('/register-success')
          }
        } else {
          this.setLoading(false)
          this.submited = false
          return false
        }
      }
    },
    openLogin () {
      // this.$router.replace('/register-verify')
      this.$bvModal.hide('modal-user-register')
      this.$bvModal.show('modal-user-login')
    },
    openForgetPassword () {
      this.$router.replace('/forgetpassword')
      this.$bvModal.hide('modal-user-login')
    },
    handleSubmit (e) {
      console.log('Sign In Button Pressed')
    }
  },
  validations () {
    return {
      username: {
        required,
        validateNo: username => {
          return checkSpecialChar(username)
        }
      },
      password: {
        required,
        validateNo: username => {
          return checkSpecialChar(username)
        }
      },
      password_confirmation: {
        required,
        validateNo: username => {
          return checkSpecialChar(username)
        }
      }
    }
  }
}
</script>
<style lang="less">
#modal-user-login{
height: 100% !important;
}
#modal-user-register{
height: 100% !important;
}
.modal-content {
    // background-color: transparent !important;
    background: var(--bodyBg);
}
.bgModalHeaderLogin {
  background: linear-gradient(180deg, var(--color-1-main));
}

.bg-primary-login {
  // background: linear-gradient( 225deg, #420000 0%, rgb(144 3 34) 40%, #420000 100%);
  background: linear-gradient(132deg, #ce878e 0%, rgb(140 140 140) 40%, rgb(152 21 21) 100%);
  border-width: 3px;
  border-style: solid;
  border-color: #7a3438;
  border-radius: 5px;
  // background-image: linear-gradient(#b5b4b4 1px, transparent 1px), linear-gradient(to right, #c8a042 1px, #c8a042 1px);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
u,
div {
  font-family: Kanit, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.container-login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  // background: -webkit-linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  // background: -o-linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  // background: -moz-linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  // background: linear-gradient(right,#21d4fd,#b721ff,#21d4fd,#b721ff);
  top: 0;
  left: -100%;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
}

.login100-form-btn {
  background: #900322;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}
</style>
