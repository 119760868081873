<template>
  <div v-if="isVisible" class="lottery-popup" @click.self="closePopup">
    <div class="lottery-popup-content">
      <!-- <span class="lottery-close" @click="closePopup">×</span> -->
      <h2>อย่าพลาดโอกาสรวยกับหวย!</h2>
      <p>เข้าร่วมสนุกกับหวยวันนี้และลุ้นรางวัลใหญ่!</p>
      <div class="row" style="max-width: 600px;">
        <div class="col-12">
          <b-carousel
            id="carousel-1"
            :interval="4000"
            controls
            indicators
          >
          <b-carousel-slide
            :img-src="require('@/assets/images/J10.webp')">
            </b-carousel-slide>
            <b-carousel-slide
            :img-src="require('@/assets/images/J12.webp')">
            </b-carousel-slide>
          <b-carousel-slide
              :img-src="require('@/assets/images/J13.webp')">
              </b-carousel-slide>
        </b-carousel>
        </div>

      </div>
      <!-- Bottom close button -->
      <span class="lottery-close-bottom" @click="closePopup">ปิด</span>
      <!-- <button class="huay-dark-highlight-button" @click="playLottery">เล่นหวยเลย!</button> -->
    </div>
  </div>
</template>
<script>
// import lottoTicketPage from '@/pages/lottoTicketPage/lottoTicketPage'

export default {
  name: 'LotteryPopup',
  components: {
    // 'app-lotto-ticket': lottoTicketPage
  },
  data () {
    return {
      isVisible: false // Controls popup visibility
    }
  },
  methods: {
    showPopup () {
      this.isVisible = true
    },
    closePopup () {
      this.isVisible = false
    },
    playLottery () {
      // Add logic to navigate to the lottery section or game page
      this.$router.push('/lottery') // Example: Navigate to /lottery route
      this.closePopup() // Close popup after action
    }
  },
  mounted () {
    // Optional: Show popup after 3 seconds (or customize trigger)
    setTimeout(() => {
      this.showPopup()
    }, 3000)
  }
}
</script>
<style scoped>
.lottery-popup {
  position: fixed;
  padding: 0 2px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Subtle semi-transparent overlay for contrast */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lottery-popup-content {
  background: linear-gradient(180deg, #f9fbff -0.7%, #ffffff 100%);
  border-radius: 12px;
  padding: 10px 7px;
  /* width: 95%; */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Light, professional shadow */
  color: #333333; /* Dark gray text for readability */
  text-align: center;
  position: relative;
}

.lottery-popup-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #2c3e50; /* Deep blue for a professional title */
}

.lottery-popup-content p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #7f8c8d; /* Light gray for subtext, professional and clear */
}

/* Top close button (circle) */
.lottery-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
  background: #e74c3c;
  box-shadow: 0 0 10px #e74c3c, 0 0 15px #e74c3c inset;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.lottery-close:hover {
  background: #c0392b;
  box-shadow: 0 0 15px #c0392b, 0 0 20px #c0392b inset;
}

/* Bottom close button (pill-shaped) */
.lottery-close-bottom {
  display: block;
  width: 120px;
  height: 40px;
  margin: 10px auto 0px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  border-radius: 20px;
  background: #e74c3c;
  box-shadow: 0 0 10px #e74c3c, 0 0 15px #e74c3c inset;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.lottery-close-bottom:hover {
  background: #c0392b;
  box-shadow: 0 0 15px #c0392b, 0 0 20px #c0392b inset;
}

.huay-dark-highlight-button {
  background: linear-gradient(135deg, #f1c40f -0.7%, #e67e22 100%) !important; /* Bright gold to orange gradient, professional and eye-catching */
  border: none;
  border-radius: 20px;
  padding: 12px 24px;
  color: #ffffff; /* White text for contrast */
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 6px 15px rgba(241, 196, 15, 0.4); /* Gold shadow for a premium look */
  transition: transform 0.3s, box-shadow 0.3s;
}

.huay-dark-highlight-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(241, 196, 15, 0.6); /* Enhanced gold shadow on hover */
}

/* Fade-in animation */
.lottery-popup-enter-active,
.lottery-popup-leave-active {
  transition: opacity 0.5s ease;
}

.lottery-popup-enter-from,
.lottery-popup-leave-to {
  opacity: 0;
}
</style>
